.navigation {
	width: 100%;
	top: 0;
	padding: 20px 0;
	position: fixed;
	z-index: 1;
	background-color: #121e2f;
	background: linear-gradient(
		to bottom,
		#121e2f 0%,
		rgba(255, 255, 255, 0) 100%
	);
	.container {
		max-width: 1210px;
		margin: 0 auto;
		padding: 0 20px;
		width: 100%;
		position: relative;
		[data-type='langswitch'] {
			position: absolute;
			top: 20px;
			right: 20px;
		}
		.logo {
			position: absolute;
			width: 150px;
			height: auto;
			left: 50%;
			opacity: 0;
			top: 20px;
			transition: all 0.2s;
			display: none;
			transform: translatex(-50%);
		}
		.toggle {
			display: none;
			width: 55px;
			height: auto;
			vertical-align: middle;
			padding: 15px;
			position: absolute;
			top: 0;
			left: 20px;
			cursor: pointer;
			cursor: pointer;
			&.open {
				span:nth-child(1) {
					transform: rotate(-45deg);
					position: relative;
					top: 7px;
				}
				span:nth-child(2) {
					max-width: 0;
					transform: translateX(-50%);
					left: 50%;
					position: relative;
				}
				span:nth-child(3) {
					transform: rotate(45deg);
					position: relative;
					bottom: 6px;
				}
			}
			span {
				display: block;
				transform: translateX(0);
				left: 0;
				max-width: 100%;
				height: 2px;
				margin: 5px 0;
				background: #fff;
				padding-top: 0;
				transition: all 0.2s;
			}
		}
		nav {
			margin: 0;
			padding: 15px 0;
			display: inline-block;
			transition: all 0.2s;
			a {
				display: inline-block;
				margin: 0px 45px 0px 0;
				text-decoration: none;
				color: #fff;
				&.active {
					color: #5bbaf4;
				}
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.navigation {
		&.open {
			background: rgba(4, 11, 20, 0.9);
			height: 100%;
			z-index: 6;
			.container {
				.logo {
					opacity: 1;
					display: block;
				}
				nav {
					display: block;
					padding-top: 70px;
				}
			}
		}
		.container {
			.toggle {
				display: block;
			}
			nav {
				display: none;
				a {
					display: block;
					padding: 15px;
					font-size: 16px;
					text-align: center;
					margin: 0;
				}
			}
		}
	}
}
