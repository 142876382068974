.mainContent {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	.contentWrap {
		.grid {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			overflow: auto;
		}
		.pageOpac {
			opacity: 0.5;
		}
	}
	.grid {
		display: block;
	}
}
