.imagetextbox {
	padding: 30px 0;
	.container {
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
		padding: 0 20px;

		> h1 {
			font-size: 40px;
			text-align: center;
			font-weight: normal;
		}

		.textbox {
			display: flex;
			flex-wrap: wrap;
			max-width: 900px;
			margin: 0 auto;
			> div {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			.imgcon {
				flex: 1;
				order: 1;
				img {
					display: block;
					width: 100%;
					padding: 15px;
				}
			}
			.lefttext {
				flex: 2;
				order: 2;
				h1 {
					margin: 0 0 15px 0;
				}
			}
		}

		.textbox {
			.reversed {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				.imgcon {
					flex: 1;
					order: 2;
				}
				.lefttext {
					flex: 2;
					order: 1;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.imagetextbox {
		.container {
			.textbox {
				max-width: 500px;
				.reversed {
					display: block !important;
				}
				.imgcon {
					flex: none !important;
					width: 100%;
					img {
						max-width: 270px;
						margin: 0 auto;
					}
				}
				.lefttext {
					flex: none !important;
					width: 100%;
					text-align: center;
					margin-top: 15px;
				}
			}
		}
	}
}
