.herobanner {
	padding: 70px 0;
	z-index: 2;

	.downarrow {
		display: block;
		margin: 0 auto;
		width: 20px;
		height: 20px;
		transform: rotate(45deg);
		background: transparent;
		border: 0;
		border-right: 2px solid #247bb1;
		outline: 0;
		border-bottom: 2px solid #247bb1;
		cursor: pointer;
		position: relative;
		bottom: 30px;
	}

	.container {
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;

		.left {
			flex: 1;
			order: 1;

			>div {
				position: relative;
				padding: 15px;

				.hbtitle {
					h1 {
						position: relative;
						margin: 0;
					}

					img {
						width: 100%;
						position: relative;
						display: block;
						padding: 0;
						margin: 0;
					}
				}

				.leftimgcon {
					.imgcon {
						position: relative;

						img {
							display: block;

						}
					}
				}

				.description {
					font-size: 30px;
					margin: 15px;
					padding: 0;
				}


				.buttons {
					margin-top: 100px;

					a {
						padding: 20px;
						text-align: center;
						color: #fff;
						text-decoration: none;
						display: block;
						max-width: 280px;
						background: rgba(0, 0, 0, 0.3);
						border-radius: 50px;
						margin: 0 0 15px 0;
						font-size: 18px;
						background: #247bb1;
						background: -moz-linear-gradient(left, #247bb1 0%, #58b5ee 100%);
						background: -webkit-linear-gradient(left,
								#247bb1 0%,
								#58b5ee 100%);
						background: linear-gradient(to right, #247bb1 0%, #58b5ee 100%);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#247bb1', endColorstr='#58b5ee', GradientType=1);

						.newTag {
							background: #57d903;
							font-weight: 700;
							padding: 3px 5px;
							border-radius: 50px;
							margin-left: 10px;
							font-size: 15px;
							display: inline-block;
						}
					}
				}
			}
		}

		.right {
			flex: 1;
			order: 2;

			div {
				position: relative;
				margin: 0 auto;
				width: 100%;
				padding-right: 15px;

				.imgcon {
					position: relative;

					img {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.herobanner {
		z-index: 2;

		.downarrow {
			display: none;
		}

		.container {
			.left {
				>div {
					padding: 15px 25px;

					.hbtitle {
						h1 {
							position: relative;
							margin: 0;
							text-align: center;
							font-size: 25px;
						}

						img {
							width: 100%;
							margin: 0 auto;
							max-width: 350px;
						}
					}

					.leftimgcon {
						.imgcon {
							max-width: 380px;
							margin: 0 auto;

							img {
								width: 100%;
								display: block;
							}
						}
					}

					.description {
						margin: 15px 15px 35px;
						text-align: center;
					}

					.buttons {
						margin-top: 50px;

						a {
							margin: 0 auto 15px;
							max-width: 350px;
							width: 100%;
						}
					}
				}
			}
		}
	}
}
