.qrsection {
	.container {
		margin: 150px auto;
		width: 100%;
		max-width: 1210px;
		padding: 20px;
	}
	.gradient {
		width: 100%;
		padding: 2px;
		position: relative;
		border-radius: 1em;
		background: linear-gradient(to right, rgb(88, 165, 142), rgb(90, 115, 226));
		.container {
			border-radius: 1em;
			background: #121e2f;
			position: relative;
			margin-top: 150px;
			height: 300px;
			width: 100%;
			max-width: 1210px;
			margin: 0 auto;
			display: flex;

			.left {
				flex: 1;
				order: 1;
				align-self: center;
				> div {
					text-align: center;
					position: relative;
					h1 {
						margin: 0;
						position: relative;
						font-weight: normal;
						font-size: 40px;
					}
					.buttons {
						margin-top: 20px;
						a {
							padding: 10px;
							margin: 0 auto;
							text-align: center;
							color: #fff;
							text-decoration: none;
							display: block;
							max-width: 226px;
							background: linear-gradient(
								to right,
								rgb(88, 165, 142),
								rgb(90, 115, 226)
							);
							border-radius: 50px;
							font-size: 18px;
							font-weight: bold;
						}
					}
				}
			}

			.right {
				flex: 1;
				order: 2;
				position: relative;
				.qrcon {
					position: relative;
					height: 100%;
					width: 100%;

					.qr {
						width: 150px;
						height: 150px;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						position: absolute;
						> div {
							width: 180px;
							height: 180px;
							position: absolute;
							top: -15px;
							left: -15px;
							span:nth-child(1) {
								width: 30px;
								height: 30px;
								border-left: 1px solid #58a58e;
								border-top: 1px solid #58a58e;
								display: block;
								position: absolute;
								top: 0;
								left: 0;
							}
							span:nth-child(2) {
								width: 30px;
								height: 30px;
								border-top: 1px solid rgb(90, 115, 226);
								border-right: 1px solid rgb(90, 115, 226);
								top: 0;
								right: 0;
								display: block;
								position: absolute;
							}
							span:nth-child(3) {
								width: 30px;
								height: 30px;
								border-left: 1px solid rgb(88, 165, 142);
								border-bottom: 1px solid rgb(88, 165, 142);
								bottom: 0;
								left: 0;
								display: block;
								position: absolute;
							}
							span:nth-child(4) {
								width: 30px;
								height: 30px;
								border-right: 1px solid rgb(90, 115, 226);
								border-bottom: 1px solid rgb(90, 115, 226);
								bottom: 0;
								right: 0;
								display: block;
								position: absolute;
							}
						}
						img {
							width: 100%;
							height: auto;
							display: block;
							position: relative;
							top: 0;
							left: 0;
						}
					}
					img {
						height: 500px;
						position: absolute;
						top: -40%;
						right: 50px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 940px) {
	.qrsection {
		.container {
			.gradient {
				.container {
					.right {
						.qrcon {
							.qr {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.qrsection {
		.container {
			margin: 50px auto 150px;
			.gradient {
				.container {
					display: block;
					padding: 50px 0;
					height: 500px;
					overflow: hidden;
					.left {
						flex: none;
						width: 100%;
						display: block;
					}
					.right {
						flex: none;
						width: 100%;
						display: block;
						.qrcon {
							.qr {
								display: none;
							}
							img {
								position: relative;
								top: 0;
								left: 0;
								margin: 45px auto;
								display: block;
							}
						}
					}
				}
			}
		}
	}
}
