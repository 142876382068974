.footercomp {
	margin-top: 80px;
	position: relative;
	background: #131415;
	.container {
		max-width: 1210px;
		padding: 50px 20px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
	}
	.footersection {
		padding: 0 15px;
		flex: 1;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
		.footertitle {
			text-transform: uppercase;
		}
		.desc {
			font-size: 14px;
			padding: 10px 0;
			color: #818181;
			h4 {
				font-size: 14px;
				margin: 0;
			}
			p {
				font-size: 12px;
			}
		}
	}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
	.footercomp {
		.footersection {
			flex: none;
			width: 50%;
		}
	}
}

@media screen and (max-width: 768px) {
	.footercomp {
		.footersection {
			flex: none;
			width: 100%;
			&:first-child {
				padding-left: 15px;
			}
			&:last-child {
				padding-right: 15px;
			}
		}
	}
}
