.latestarticles {
	.container {
		max-width: 1210px;
		margin: 20px auto;
		width: 100%;
		padding: 0;
		> div {
			display: block;
			.postitem {
				width: 50%;
				display: inline-block;
				padding: 15px;
				a {
					display: block;
					width: 100%;
					padding: 15px 10px;
					border: 1px solid #4a8eb9;
					border-radius: 10px;
					text-decoration: none;
					p {
						color: #fff;
						font-size: 17px;
						margin: 5px 0 5px 0;
					}
					span {
						color: #4a8eb9;
						opacity: 0.6;
						font-size: 14px;
					}
					img {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.latestarticles {
		.container {
			> div {
				display: block;
				.postitem {
					width: 100%;
					display: inline-block;
					padding: 15px;
				}
			}
		}
	}
}
