.backgroundcon {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	.topbg {
		width: 1920px;
		position: relative;
		top: -330px;
		left: 50%;
		transform: translateX(-50%);
		display: block;
	}
}

@media screen and (max-width: 960px) {
	.backgroundcon {
		.topbg {
			width: 1920px;
			position: relative;
			top: -30%;
			left: 50%;
			transform: translateX(-50%);
			display: block;
		}
	}
}
