.langswitch {
	display: block;
	width: 25px;
	height: 25px;
	position: relative;
	.drop {
		width: 25px;
		height: 25px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		cursor: pointer;
	}
	.langdropdown {
		position: absolute;
		max-height: 700px;
		top: 0;
		left: 0;
		overflow: hidden;
		width: calc( 100% + 2px);
		margin-top: 40px;
		border: 1px solid transparent;
		border-top-color: #fff;
		padding-top: 20px;
		transition: all 0.5s;
		span {
			display: flex;
			width: 25px;
			height: 25px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			margin-bottom: 10px;
			cursor: pointer;
			&[selected='true'] {
				display: none;
			}
		}
	}
}
