.featuredArticles {
  margin-bottom: 50px;

  .container {
    margin: 0 auto;
    padding: 0px 20px;
    width: 100%;
    max-width: 1210px;

    h3 {
      background: #5f9ea0;
      color: #fff;
      font-size: 36px;
      line-height: 100px;
      margin: 10px;
      padding: 2%;
      position: relative;
      text-align: center;
    }

    .articleContainer {
      .article {
        width: 100%;
        a {
          display: block;
        }
        img {
          display: block;
          width: 100%;
        }
        .articleContent {
          display: flex;
          flex-wrap: wrap;
          margin: 15px 0;
          .left {
            flex: 2;
            align-self: center;
            h1 {
              font-size: 20px;
              color: #fff;
            }
            p {
              font-size: 14px;
              color: #596e7f;
            }
          }
          .right {
            flex: 1;
            text-align: right;
            position: relative;
            .tags {
              button {
                background: #23314d;
                color: #fff;
                margin: 10px 0 10px 10px;
                border-color: transparent;
                opacity: 0.5;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                outline: none;
                display: inline-block;
                width: auto;
                border-radius: 50px;
                padding: 5px 15px;
              }
            }
            a {
              display: block;
              margin: 0;
              background: #4a8eb9;
              color: #fff;
              border-radius: 50px;
              outline: none;
              border-color: transparent;
              padding: 8px 15px;
              font-size: 14px;
              width: 100%;
              max-width: 200px;
              text-align: center;
              text-decoration: none;
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }
      }

      /* Pagination Dots Style */
      .pagination {
        text-align: center;
        margin-top: 20px;

        .pageDot {
          background-color: #23314d;
          border: none;
          border-radius: 50%;
          width: 10px;
          height: 12px;
          margin: 0 5px;
          cursor: pointer;
          opacity: 0.5;
        }

        .activeDot {
          opacity: 1;
          background-color: #4a8eb9;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .featuredArticles {
    .container {
      .articleContainer {
        .article {
          .articleContent {
            .left {
              display: block;
              width: 100%;
              flex: none;
            }
            .right {
              display: block;
              width: 100%;
              flex: none;
              text-align: left;
              .tags {
                button {
                  &:nth-child(1) {
                    margin-left: 0;
                  }
                }
              }
              a {
                position: relative;
                top: 0;
                left: 0;
                margin: 15px 0;
              }
            }
          }
        }
      }
    }
  }
}
