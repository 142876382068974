.stories {
	.container {
		width: 100%;
		padding: 0;
		max-width: 1210px;
		margin: 0 auto;
		.tags {
			display: block;
			display: block;
			padding: 20px 20px 10px;
			button {
				background: #23314d;
				color: #fff;
				margin: 0 10px 0 0;
				border-color: transparent;
				opacity: 0.5;
				-webkit-user-select: none;
				-ms-user-select: none;
				user-select: none;
				outline: none;
				display: inline-block;
				width: auto;
				border-radius: 50px;
				padding: 5px 15px;
				cursor: pointer;
			}
		}
		.btnmore {
			display: block;
			margin: 15px auto 0;
			background: #4a8eb9;
			color: #fff;
			border-radius: 50px;
			outline: none;
			text-decoration: none;
			border-color: transparent;
			padding: 8px 15px;
			font-size: 14px;
			width: 100%;
			max-width: 200px;
			text-align: center;
			cursor: pointer;
		}
		.postitem {
			padding: 20px;
			display: inline-block;
			width: 33.33%;
			vertical-align: middle;
			a {
				text-decoration: none;
				display: flex;
				padding-bottom: 25px;
				border-bottom: 1px solid #243249;
				.postimage {
					width: 80px;
					height: 80px;
					overflow: hidden;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					border-radius: 10px;
					display: inline-block;
					vertical-align: middle;
				}
				.postdetail {
					display: inline-block;
					vertical-align: middle;
					padding: 15px;
					p {
						display: block;
						padding: 0;
						margin: 0 0 10px 0;
						color: #fff;
						font-size: 16px;
					}
					span {
						display: block;
						color: #6c8395;
						font-size: 13px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 940px) {
	.stories {
		.container {
			.postitem {
				width: 50%;
			}
		}
	}
}

@media screen and (max-width: 640px) {
	.stories {
		.container {
			.postitem {
				width: 100%;
			}
		}
	}
}
