.postcon {
	.container {
		max-width: 1210px;
		padding: 80px 20px;
		width: 100%;
		margin: 0 auto;
		border-bottom: 1px solid #283b47;
		.left {
			width: 70%;
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 50px;
			.title {
				margin-top: 30px;
				margin-bottom: 50px;
				h1 {
					margin-bottom: 5px;
				}
				span {
					color: #4a8eb9;
					opacity: 0.6;
					font-size: 14px;
				}
				.tags {
					margin-top: 20px;
					span {
						background: #23314d;
						color: #fff;
						margin: 0 10px 0 0;
						border-color: transparent;
						opacity: 0.5;
						-webkit-user-select: none;
						-ms-user-select: none;
						user-select: none;
						outline: none;
						display: inline-block;
						width: auto;
						border-radius: 50px;
						padding: 5px 15px;
						cursor: pointer;
					}
				}
			}
			.content {
				img {
					display: block;
					width: 100%;
					margin-bottom: 30px;
				}
				p {
					font-size: 16px;
				}
			}
		}
		.right {
			width: 30%;
			display: inline-block;
			vertical-align: top;
			padding-left: 50px;
			> div {
				> div {
					> div {
						> div {
							width: 100%;
							padding-left: 0;
							padding-right: 0;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 940px) {
	.postcon {
		.container {
			.left {
				width: 100%;
				display: block;
			}
			.right {
				width: 100%;
				display: block;
				padding: 0;
			}
		}
	}
}
